// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\priv\\repos\\personal-website\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layouts-blog-tag-page-tsx": () => import("C:\\priv\\repos\\personal-website\\src\\layouts\\blog-tag-page.tsx" /* webpackChunkName: "component---src-layouts-blog-tag-page-tsx" */),
  "component---src-layouts-blog-post-tsx": () => import("C:\\priv\\repos\\personal-website\\src\\layouts\\blog-post.tsx" /* webpackChunkName: "component---src-layouts-blog-post-tsx" */),
  "component---src-layouts-blog-category-tsx": () => import("C:\\priv\\repos\\personal-website\\src\\layouts\\blog-category.tsx" /* webpackChunkName: "component---src-layouts-blog-category-tsx" */),
  "component---src-pages-404-tsx": () => import("C:\\priv\\repos\\personal-website\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("C:\\priv\\repos\\personal-website\\src\\pages\\blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("C:\\priv\\repos\\personal-website\\src\\pages\\contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-full-page-post-tsx": () => import("C:\\priv\\repos\\personal-website\\src\\pages\\full-page-post.tsx" /* webpackChunkName: "component---src-pages-full-page-post-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\priv\\repos\\personal-website\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("C:\\priv\\repos\\personal-website\\src\\pages\\portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */)
}

