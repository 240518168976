module.exports = [{
      plugin: require('C:/priv/repos/personal-website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/priv/repos/personal-website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/priv/repos/personal-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
